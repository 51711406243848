import React, { useCallback, useState } from 'react';
import Logo from '../assets/Logo.png';
import QRCode from '../assets/QRCode.png'; // Make sure to add this QR code image
import { XIcon, ChevronRightIcon } from '@heroicons/react/outline';


const DemoForm = ({ onClose }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    masjidName: '',
    lookingForDonationPlatform: '',
    bestContactMethod: ''
  });
  const [emailError, setEmailError] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleBackgroundClick = useCallback((e) => {
    if (e.target.id === 'modal-background') {
      onClose();
    }
  }, [onClose]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
    if (name === 'email') {
      setEmailError(!isValidEmail(value));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log(JSON.stringify(formData));
      const response = await fetch('https://formspree.io/f/mwpedpwy', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        console.log('Form submitted successfully');
        setIsSubmitted(true);
      } else {
        console.error('Form submission failed');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  const isValidEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const isFormValid = () => {
    return formData.name && isValidEmail(formData.email) && formData.phone && formData.masjidName && formData.masjidCity &&
           formData.lookingForDonationPlatform && 
           (formData.lookingForDonationPlatform === 'No' || formData.bestContactMethod);
  };

  const handleLearnMoreClick = () => {
    document.querySelector('#issue').scrollIntoView({ behavior: 'smooth' });
  };

  const renderThankYouContent = () => (
    <div className="text-center">
      <img
        className="h-10 w-auto mb-6 mx-auto"
        src={Logo}
        alt="GiveMasjid"
      />
      <hr></hr>
      <h2 className="text-2xl font-extrabold text-gray-900 mb-4 mt-4">Your Live Demo is Ready</h2>
      <p className="text-lg mb-4">
        Click <a href="https://donate.givemasjid.com/demo" className="text-indigo-600 hover:text-indigo-800 font-semibold" target="_blank" rel="noopener noreferrer"> this link  </a> to visit the donation page directly.
      </p>
      <p className="text-lg mb-4">Or scan the below qr code to try it on mobile.</p>
      <img
        className="w-48 h-48 mx-auto mb-6"
        src={QRCode}
        alt="QR Code"
      />
        <p className="text-lg mb-4 italic">
        Contact us at salam@givemasjid.com if you have any questions!
         </p>
      <button
        onClick={onClose}
        className="w-full bg-indigo-600 text-white py-2 px-4 rounded-md hover:bg-indigo-700 transition duration-300"
      >
        Close
      </button>
    </div>
  );

  return (
    <div 
      id="modal-background"
      className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center z-50" 
      onClick={handleBackgroundClick}
    >
      <div className="relative p-6 border w-[800px] shadow-lg rounded-md bg-white">
        <button
            onClick={onClose}
            className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 focus:outline-none"
            style={{
              // Ensure the button is at least 48x48 pixels
              width: 72,
              height: 72,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            >
              <XIcon className="h-6 w-6" />


          </button>
        {isSubmitted ? renderThankYouContent() : (
          <div className="mt-3">
            <img
              className="h-7 w-auto mb-4"
              src={Logo}
              alt="GiveMasjid"
            />
            <hr className="w-full mb-4"></hr>

            <h2 className="text-2xl font-extrabold text-gray-900 mb-2">Try a <span className="text-indigo-600">Demo</span></h2>
            <p className="text-base lg:text-base text-gray-600 mb-6 ">
              Asalamu Alaikum from the GiveMasjid Team! 👋 <span className="mt-2 inline-block">To get started with your donation demo, we'll just need you to answer a few quick questions. Your demo will load up immediately after. Enjoy!</span>
            </p>
            <form onSubmit={handleSubmit} className="space-y-6">
              <input type="hidden" name="form-name" value="demo-form" />
              <div className="flex items-center">
                <label htmlFor="name" className="block text-base lg:text-base font-medium text-gray-700 w-1/3">Name</label>
                <input type="text" id="name" name="name" value={formData.name} onChange={handleInputChange} className="mt-1 px-3 py-2 border rounded-md w-2/3 text-base lg:text-base" required />
              </div>
              <div className="flex items-center">
                <label htmlFor="email" className="block text-base lg:text-base font-medium text-gray-700 w-1/3">Email Address</label>
                <div className="w-2/3">
                  <input 
                    type="email" 
                    id="email" 
                    name="email" 
                    value={formData.email} 
                    onChange={handleInputChange} 
                    className={`mt-1 px-3 py-2 border rounded-md w-full text-base lg:text-base ${emailError ? 'border-red-500' : ''}`}
                    required 
                    pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
                  />
                  {emailError && <p className="text-red-500 text-xs mt-1">Please enter a valid email address</p>}
                </div>
              </div>
              <div className="flex items-center">
                <label htmlFor="phone" className="block text-base lg:text-base font-medium text-gray-700 w-1/3">Phone Number</label>
                <input type="tel" id="phone" name="phone" value={formData.phone} onChange={handleInputChange} className="mt-1 px-3 py-2 border rounded-md w-2/3 text-base lg:text-base" required />
              </div>
              <div className="flex items-center">
                <label htmlFor="masjidName" className="block text-base lg:text-base font-medium text-gray-700 w-1/3">Masjid Name</label>
                <input type="text" id="masjidName" name="masjidName" value={formData.masjidName} onChange={handleInputChange} className="mt-1 px-3 py-2 border rounded-md w-2/3 text-base lg:text-base" required />
              </div>
              <div className="flex items-center">
                <label htmlFor="masjidCity" className="block text-base lg:text-base font-medium text-gray-700 w-1/3">Masjid City, State</label>
                <input type="text" id="masjidCity" name="masjidCity" value={formData.masjidcity} onChange={handleInputChange} className="mt-1 px-3 py-2 border rounded-md w-2/3 text-base lg:text-base" required />
              </div>
              <div className="space-y-2">
                <p className="text-base lg:text-base font-medium text-gray-700 mb-4">Are You Looking to Improve How Your Masjid Collects or Manages Donations?</p>
                <div className="flex space-x-4">
                  <label className="inline-flex items-center">
                    <input type="radio" name="lookingForDonationPlatform" value="Yes" onChange={handleInputChange} className="form-radio text-indigo-600" required />
                    <span className="ml-2 text-base lg:text-base">Yes</span>
                  </label>
                  <label className="inline-flex items-center">
                    <input type="radio" name="lookingForDonationPlatform" value="No" onChange={handleInputChange} className="form-radio text-indigo-600" required />
                    <span className="ml-2 text-base lg:text-base">No</span>
                  </label>
                  <label className="inline-flex items-center">
                    <input type="radio" name="lookingForDonationPlatform" value="Maybe / Unsure" onChange={handleInputChange} className="form-radio text-indigo-600" required />
                    <span className="ml-2 text-base lg:text-base">Maybe / Unsure</span>
                  </label>
                </div>
              </div>
              {(formData.lookingForDonationPlatform === 'Yes' || formData.lookingForDonationPlatform === 'Maybe / Unsure') && (
                <div className="space-y-2">
                  <p className="text-base lg:text-base font-medium text-gray-700">What's the best way to contact you?</p>
                  <div className="flex space-x-4">
                    <label className="inline-flex items-center">
                      <input type="radio" name="bestContactMethod" value="Email" onChange={handleInputChange} className="form-radio text-indigo-600" required />
                      <span className="ml-2 text-base lg:text-base">Email</span>
                    </label>
                    <label className="inline-flex items-center">
                      <input type="radio" name="bestContactMethod" value="Phone" onChange={handleInputChange} className="form-radio text-indigo-600" required />
                      <span className="ml-2 text-base lg:text-base">Phone</span>
                    </label>
                    <label className="inline-flex items-center">
                      <input type="radio" name="bestContactMethod" value="Text" onChange={handleInputChange} className="form-radio text-indigo-600" required />
                      <span className="ml-2 text-base lg:text-base">Text</span>
                    </label>
                  </div>
                  {formData.bestContactMethod && (
                    <div className='pt-3'>
                      <p className="text-base lg:text-base text-indigo-800">
                        ☝️ <span className="italic font-medium"> Great! A member of our team will reach out soon, inshallah.</span>
                      </p>
                    </div>
                  )}
                </div>
              )}
              <hr></hr>
              <button
                type="submit"
                className={`w-full flex justify-center items-center py-2 px-4 border border-transparent rounded-md shadow-sm text-lg font-medium text-white ${
                  isFormValid() ? 'bg-indigo-600 hover:bg-indigo-700' : 'bg-gray-400 cursor-not-allowed'
                } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                disabled={!isFormValid()}
              >
                Try It Now
                {isFormValid() && <ChevronRightIcon className="h-6 w-6 ml-2" />}

              </button>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default DemoForm;
