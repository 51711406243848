// src/components/CelebratonModal.jsx
import React from 'react';
import { XIcon, GiftIcon, CalendarIcon, UserGroupIcon } from '@heroicons/react/outline';

const CelebrationModal = ({ isOpen, onClose, isVisible }) => {
  if (!isOpen) return null;

  return (
    <div className={`fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50 transition-opacity duration-300 ${
      isVisible ? 'opacity-100' : 'opacity-0'
    }`}>
      <div className={`relative top-5 lg:top-20 mx-auto p-8 border w-full max-w-4xl shadow-2xl rounded-md bg-gradient-to-br from-white to-indigo-50 transition-transform duration-300 ${
        isVisible ? 'scale-100' : 'scale-95'
      }`}>
        <div className="mt-3 text-center">
          <h3 className="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl mb-4">
            <span className="text-indigo-600 animate-pulse">Alhamdulillah!</span>
          </h3>

          <div className="space-y-8">
            <div>
              <p className="hidden lg:block text-xl text-gray-700 mb-2">
                We just reached a major milestone! Our masjid partners have <span className="italic">just</span> raised over <span className="font-bold text-emerald-600 animate-bounce inline-block">$500,000</span> since we first launched GiveMasjid. Inshallah, with Allah's Help, we look forward to seeing these masjids raise their first $1M in the months ahead.
              </p>
              <div className="lg:hidden">
                <p className="text-lg text-gray-700 mb-2">
                  Our masjid partners have <span className="italic">just</span> raised over
                </p>
                <p className="font-bold text-emerald-600 text-4xl animate-bounce mb-2 mt-5">
                  $500,000
                </p>
                <p className="text-lg text-gray-700">
                  since we first launched GiveMasjid. Inshallah, with Allah's Help, we look forward to seeing our masjid partners raise their first $1M in the months ahead!
                </p>
              </div>
            </div>

            <div className="bg-indigo-100 p-3 lg:p-6 rounded-lg text-center">
              <div className="bg-white h-20 w-20 rounded-full flex items-center justify-center mx-auto mb-4">
                <GiftIcon className="h-12 w-12 text-indigo-600" />
              </div>
              <p className="text-lg lg:text-xl text-gray-700 font-medium mb-2">
                To celebrate, <span className="text-indigo-800 font-semibold">we're waiving our fees on the first $5,000 raised</span> for any masjid that signs up through January 31, 2025. Sign up for a trial to learn more.
              </p>
            </div>
          </div>

          <div className="mt-10 sm:flex sm:justify-center">
            <button
              className="w-full sm:w-auto px-8 py-3 border border-transparent text-lg font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-xl md:px-10 hover:font-bold transition-all transform hover:scale-105"
              onClick={onClose}
            >
              Alhamdulillah!
            </button>
          </div>
        </div>

        <button
          type="button"
          className="absolute top-4 right-4 bg-white rounded-full p-2 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-all transform hover:rotate-90"
          onClick={onClose}
        >
          <span className="sr-only">Close</span>
          <XIcon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
    </div>
  );
};

export default CelebrationModal;