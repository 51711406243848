import React, { useRef, useState, useEffect } from 'react';

import { AnnotationIcon, GlobeAltIcon, LightningBoltIcon, MailIcon, ScaleIcon, CashIcon, TrendingDownIcon, EyeOffIcon, StatusOfflineIcon, CreditCardIcon, TrendingUpIcon, PresentationChartBarIcon, KeyIcon, CurrencyDollarIcon, HeartIcon, GiftIcon, MoonIcon, PresentationChartLineIcon, QrcodeIcon, QuestionMarkCircleIcon, ClockIcon, ChevronDoubleUpIcon, ThumbDownIcon, XCircleIcon, DocumentReportIcon, RefreshIcon } from '@heroicons/react/outline'
import box1 from '../assets/Box1.png';
import box2 from '../assets/Box2.png';
import box3 from '../assets/Box3.png';
import box4 from '../assets/Box4.png';
import box5 from '../assets/Box5.png';

import boxes from '../assets/Boxes.gif';
import app from '../assets/App1.png';
import app2 from '../assets/App2.png';
import jummah from '../assets/jummah.png';
import appvid from '../assets/AppVid.gif';
import flyer from '../assets/flyer_shot.png';
import flyer_app2 from '../assets/Flyer_App_Combo_2.png';
import flyer_app from '../assets/Flyer_App_Combo.png';
import flyer_app_video from "../assets/App_With_Frame_New.mp4";
import qrgirl from '../assets/GirlQR.png';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid'
import { HashLink } from 'react-router-hash-link';


import { Fade, Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';


const images = [box1, box2, box3, box4];



const currentIssues = [
  {
    id: 1,
    name: "Donors Don't Carry Cash",
    description:
      "Today, just 15% of the population still carries cash on hand — and even when they do, the average amount held is less than $50. If your masjid still receives most of its donations in cash, your typical donation is likely ~3-5x smaller than what it could be.",
    icon: XCircleIcon,
  },
  {
    id: 2,
    name: "Donors Don't Trust Collection Boxes",
    mobilename: "Donors Don't Trust Donation Bins",
    description:
      "When donors see collection boxes at masjids, they typically avoid depositing large donations. While donors are willing to offer pocket cash, they routinely hold back donations above $100 — even when willing to give significantly more. By consistently  missing out on these large-ticket donations, your masjid sets itself back when it comes to financial well-being.",
    icon: XCircleIcon,
  },
  {
    id: 3,
    name: "Donors Expect Mobile Giving Experiences",
    mobilename: "Donors Expect Mobile Payments",
    description:
      "If your masjid doesn't have the resources to create a truly polished website and mobile-responsive donation page, it's likely having an impact on your masjid's fundraising trends. Donors are increasingly using their phones to navigate the web and make donations. If your website looks disorganized and displays poorly on phones, donors are less likely to trust its security and function.",
    icon: XCircleIcon,
  },
  {
    id: 4,
    name: "Donors Hate Using Venmo, Zelle, and Paypal",
    description:
      "While many masjids opt for simple peer-to-peer payment solutions, these tools typically come with a host of issues. If your masjid relies on proprietary apps like Venmo and Zelle — your masjid is losing out on donors who don't have these tools readily installed and on donors too pressed for time. And when you use generic platforms like Paypal, your masjid receives consistently smaller donations and fewer donations on average, because of the uninspiring checkout process and limited payment methods.",
    icon: XCircleIcon,
  }
]
const keyFeatures = [

  {
    id: 5,
    name: 'Accept Any Payment Type with Ease',
    mobilename: "Accept Any Payment Type",
    description:
      "Through GiveMasjid, your masjid will have its very own fast, frictionless, and easy-to-use donation system. It works great on both desktops and mobile phones. No more clunky, confusing, or dated interfaces. Your masjid will immediately be able to accept all payment methods: Credit Card, ACH Bank Transfer, Apple Pay, Google Pay, Stripe Link, Cash App, and more. The platform looks great, works smoothly, and is perfect for both in-person and online donations. No app download or login required.",
    icon: CreditCardIcon,
  },
  {
    id: 6,
    name: 'Offer Convenience with Instant Donations',
    mobilename: "Offer Easy, Instant Donations",
    description:
      "Faster donations mean more donations. And with GiveMasjid, your donors will be able to offer a one-time or recurring donation in less than 30 seconds. Just a quick scan and a few taps — and your donors will have completed their donation. And because it supports Apple Pay and Google Pay, there's no need for donors to search for their credit card. We make it extremely easy for donors to support your masjid.",
    icon: LightningBoltIcon,
  },
  {
    id: 7,
    name: "Encourage Larger and More Sustained Giving",
    mobilename: "Encourage Repeat Contributions",
    description:
      "The average GiveMasjid donation is 5x larger than the typical cash donation and nearly 20% of donations come in the form of recurring subscriptions. Our platform has been designed to make it easy for donors to select from a host of pledge options by day, week, month, or year. When donors have flexibility and a great experience, they are more likely to give in big ways.",
    icon: RefreshIcon,
  },
  {
    id: 8,
    name: "Engage Donors with Masjid Campaigns",
    mobilename: "Engage with Masjid Campaigns",
    description:
      "We've designed GiveMasjid to be a modern, purpose-built system specifically for masjids. It's complete with features based on the asks of Muslim donors and masjid volunteers. Live Goal Tracking, Jummah Pledges, Ramadan Donation Drives, Charity Categorizations, Islamic School Payment Systems, and more. We make it easier for masjids to engage with their donor base in new ways and raise more money.",
  icon: ChevronDoubleUpIcon,
  },
  {
    id: 9,
    name: 'Streamline Tax Receipts and Reporting',
    mobilename: "Manage Receipts and Reporting",
    description:
      "With GiveMasjid, we handle all the annoying bits of financial reporting. Donors receive tax receipts after every donation and donation summaries at the end of each year. Your masjid receives regular reports on the donation history and trends straight to your inbox. We routinely review this data with you to share insights based on our analysis and trends across our broader masjid network. We're here to help you succeed.",
    icon: DocumentReportIcon,
  },

]


export default function Platform() {
  const [expandedFeatureId, setExpandedFeatureId] = useState(null); 
  const [expandedFeatures, setExpandedFeatures] = useState({});
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          if (!isPlaying) {
            setIsPlaying(true);
            setTimeout(() => {
              videoRef.current?.play().catch(error => {
                // Handle any play() errors here
                console.error("Error playing video:", error);
              });
            }, 100);
          }
        } else {
          if (isPlaying) {
            setIsPlaying(false);
            videoRef.current?.pause();
          }
        }
      });
    }, options);

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, [isPlaying]);

  const toggleFeature = (id) => {
    setExpandedFeatureId(prevId => (prevId === id ? null : id));
  };

  const renderItems = (items, colorClass) => {
    return items.map((item) => (
      <div key={item.id} className="relative mt-5 sm:mt-6">
        <div
          className="bg-white rounded-lg shadow-md overflow-hidden cursor-pointer transition-all duration-500 ease-out"
          onClick={() => toggleFeature(item.id)}
        >
          <div className="px-3 py-4 sm:px-4 sm:py-5 sm:p-6">
            <div className="flex items-center justify-between">
              <div className="flex items-center flex-grow mr-2 sm:mr-4">
                <div className="flex-shrink-0">
                <div className={`flex items-center justify-center h-9 w-9 sm:h-12 sm:w-12 rounded-md ${colorClass} text-white`}>
                    <item.icon className="h-5 w-5 sm:h-6 sm:w-6" aria-hidden="true" />
                  </div>
                </div>
                <div className="ml-3 sm:ml-4">
                   <h3 className="text-md lg:text-xl font-semibold text-gray-900 leading-6">
                    <span className="lg:hidden">{item.mobilename || item.name}</span>
                    <span className="hidden lg:inline">{item.name}</span>
                  </h3>
                </div>
              </div>
              <div className="flex items-center justify-center h-6 w-6 sm:h-8 sm:w-8 bg-gray-100 rounded-full transition-all duration-300 ease-in-out hover:bg-gray-200">
                {expandedFeatureId === item.id ? (
                  <ChevronUpIcon className="h-6 w-6 text-gray-600 animate-bounce" />
                ) : (
                  <ChevronDownIcon className="h-6 w-6 text-gray-600 animate-pulse" />
                )}
              </div>
            </div>
          </div>
          <div
            className={`overflow-hidden transition-all duration-300 ease-in-out ${expandedFeatureId === item.id ? 'max-h-96 opacity-100' : 'max-h-0 opacity-0'
              }`}
          >
            <div className="px-4 pb-5 sm:px-6 sm:pb-6">
              <p className="text-base text-gray-500">{item.description}</p>
            </div>
          </div>
        </div>
      </div>
    ));
  };

  return (
    <div className="bg-white overflow-hidden sm:py-24 md:py-6" id="how">
      <div className="relative max-w-xl mx-auto px-6 sm:px-6 lg:px-8 lg:max-w-7xl z-10">

        {/* Updated centered headline */}
        <div className="sm:text-center mb-10 mt-10 sm:mt-1 md:mt-10">
          <h2 className="text-3xl sm:text-5xl font-extrabold text-gray-900 relative inline-block pb-4">
            <span className="text-indigo-600">How We Help </span> Masjids <span class="hidden sm:inline">Succeed</span>

            <div className="absolute bottom-0 left-0 w-full h-1 bg-indigo-500"></div>
          </h2>
        </div>

        {/* Graphic */}
        <svg
          className="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12 -z-10"
          width={404}
          height={784}
          fill="none"
          viewBox="0 0 404 784"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width={404} height={784} fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" />
        </svg>

        {/* Problem */}
        <div className="relative lg:mt-8 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center " >
          <div className="relative">
            <h3 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
              The Masjid <span className="text-red-600">Donation Box</span>
            </h3>
            <p className="mt-3 sm:text-lg text-gray-500">
              Most masjids collect donations through unloved, back-corner cash collection bins and unsophisticated payment systems. If this sounds like your masjid, you might be missing out on hundreds of thousands of dollars in lost potential donations. <b>Why?</b>
            </p>

            <dl className="mt-5 sm:mt-10 sm:space-y-10">
                {renderItems(currentIssues, 'bg-red-500')}
              </dl>
          </div>

          <div className="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
            <svg
              className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
              width={784}
              height={404}
              fill="none"
              viewBox="0 0 784 404"
            >
              <defs>
                <pattern
                  id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={784} height={404} fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)" />
            </svg>
            {/* <img
              className="relative mx-auto sm:rounded-xl"
              width={550}
              src={boxes}
              alt=""
            /> */}

            <div className="slide-container mx-auto">
              <Slide
                autoplaypauseOnHover={true}
                autoplay={true}
                canSwipe={true}
                indicators={true}
                arrows={true}
                duration={3500}
                infinite={true}
                transitionDuration={700}
                easing={"ease"}
              >
                {images.map((image, index) => (
                  <div className="each-slide flex justify-center" key={index}>
                    <div
                      className="slide-container"
                      style={{
                        width: '550px', // Adjust the width as needed
                        height: 'auto', // The height will be automatically adjusted
                      }}
                    >
                      <img className="sm:rounded-xl mx-auto lg:mt-5" src={image} alt={`Slide ${index}`} />
                    </div>
                  </div>
                ))}
              </Slide>
            </div>
          </div>
        </div>


        {/* Feature 1 */}
        <div className="relative mt-2 sm:mt-16 lg:mt-18">
          <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
            <div className="lg:col-start-2">
              <h3 className="pt-10 text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">The <span className="text-indigo-600">GiveMasjid </span> Platform</h3>
              <p className="mt-3 text-base text-gray-500">
                With GiveMasjid, you can offer your community a better donation experience — one that inspires confidence and incentivizes them to give generously.
              </p>

              <dl className="mt-5 sm:mt-10 sm:space-y-10">
                {renderItems(keyFeatures, 'bg-indigo-500')}
              </dl>
              <div className="text-center mt-10">
                {/* <div className="mt-3 sm:mt-0">
                    <HashLink
                      smooth to="/#loved-by-donors"
                      className="flex items-center justify-center px-8 py-3 border shadow-md text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 border-slate-300 border-3  hover:bg-indigo-600 hover:text-white hover:font-bold"
                    >
                      > See Donor Testimonials
                    </HashLink>
                </div> */}

              </div>
            </div>

            <div className="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
              <svg
                className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
                width={784}
                height={404}
                fill="none"
                viewBox="0 0 784 404"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="e80155a9-dfde-425a-b5ea-1f6fadd20131"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width={784} height={404} fill="url(#e80155a9-dfde-425a-b5ea-1f6fadd20131)" />
              </svg>

              <video
                className="relative mx-auto rounded-3xl "
                style={{ clipPath: 'inset(1px 1px)' }}
                width={1000}
                ref={videoRef}
                muted
                playsInline
              >
                <source src={flyer_app_video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>


            </div>
          </div>
        </div>

      </div>
    </div>
  )
}
