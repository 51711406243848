import React, { useRef, useEffect, useState } from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

import { AnnotationIcon, GlobeAltIcon, MailIcon, ScaleIcon, CashIcon, TrendingDownIcon, UserGroupIcon, CreditCardIcon, TrendingUpIcon, PresentationChartBarIcon, KeyIcon, CurrencyDollarIcon, HeartIcon, GiftIcon, MoonIcon, PresentationChartLineIcon, QuestionMarkCircleIcon, ClockIcon, UsersIcon, AcademicCapIcon, ClipboardListIcon, ReceiptTaxIcon, ChevronUpIcon, ChevronDownIcon} from '@heroicons/react/outline'
import box1 from '../assets/Box1.png'; 
import box2 from '../assets/Box2.png';
import box3 from '../assets/Box3.png';
import boxes from '../assets/Boxes.gif';
import app from '../assets/App1.png'; 
import app2 from '../assets/App2.png'; 
import jummah from '../assets/jummah.png'; 
import appvid from '../assets/AppVid.gif'; 
import flyer from '../assets/flyer_shot.png'; 
import flyer_app2 from '../assets/Flyer_App_Combo_2.png'; 
import flyer_app from '../assets/Flyer_App_Combo.png'; 
import { HashLink } from 'react-router-hash-link';

import app_1 from '../assets/1.png';
import app_2 from '../assets/2.png';
import app_3 from '../assets/3.png';
import app_3b from '../assets/3b.png';
import app_4 from '../assets/4.png';
import app_4b from '../assets/4b.png';


import app_5a from '../assets/5a.png';
import app_5 from '../assets/5.png';
import app_6 from '../assets/6.png';
import app_7 from '../assets/7.png';
import app_8 from '../assets/8.png';
// import app_8 from '../assets/app_School.png';


const images1 = [app_1, app_2, app_3, app_3b, app_4];
const images2 = [app_4b,app_5, app_6, app_7, app_5a,];


const features3 = [
  {
    id: 95,
    name: 'No Upfront or Annual Fees',
    description:
      "We want you to try GiveMasjid risk-free. We'll cover all costs to set the platform up for your masjid at no cost and we'll offer you dedicated support to ensure the implementation is successful. If ultimately GiveMasjid is not for you, we'll even help you find a different solution.",
    icon: HeartIcon,
  },
  {
    id: 96,
    name: 'Low to No-Cost Transactions',
    description:
      "As a Muslim-run organization, we're looking to help masjids keep what they raise. Our fees are among the lowest of any donation platform out there. We charge only a 0.80% platform fee to cover our expenses. And because of how GiveMasjid is setup, the vast majority of these fees are covered by donors themselves.",
    icon: ScaleIcon,
  },
  {
    id: 97,
    name: 'Simple Financial Reporting',
    description:
      'GiveMasjid helps you to manage incoming donations simply. Your masjid will have acess to intuitive reports — complete with donation history, analytics, and customizable settings. Reports get sent straight to your inbox for convenient management.',
    icon: PresentationChartBarIcon,
  },
  {
    id: 102,
    name: "Automated Tax Receipts",
    description:
      "We make tax receipts simple for your masjid. Each donor receives an individual receipt after every donation and an end-of-year statement summarizing their complete donation history to your masjid. We make it easy for your donors to claim their tax deducation for supporting your masjid.",
    icon: ReceiptTaxIcon,
  },
  {
    id: 98,
    name: 'Secured Transactions ',
    description:
      'GiveMasjid is built on top of the  Stripe API, the same payment processor used by Amazon, Salesforce, and Lyft. Your donors can trust that their data is safe and securely handled through a PCI-compliant system that will feel familiar to them.',
    icon: KeyIcon,
    },
    {
      id: 99,
      name: 'Next Day Deposits',
      description:
        "All donations deposit directly into your masjid's bank account. Funds can be withdrawn within 1-2 days of receipt along with weekly reports on donations received.",
      icon: CurrencyDollarIcon,
    },
]

const features4 = [

  {
    id: 100,
    name: 'Automated Jummah Collection',
    mobilename: "Automated Jummah Pledges",
    description:
      "Through GiveMasjid, your donors can set up recurring donations to your masjid on a daily, weekly, monthly, or yearly basis. They can even specify the number of payments. Each Friday, we especially nudge donors to sign up for a weekly Jummah Pledge. No more forgotten donations. No more chasing donors.",
    icon: ClockIcon,
  },

  {
    id: 101,
    name: "Trackable Donation Categories",
    mobilename: "Trackable Categories",
    description:
      "We make it easy for your masjid to create specific donation causes. Donors can select these causes when donating and your masjid receives clear reporting on how much has been raised for each cause. It's a great feature to help masjids manage Sadaqah and Zakat payments, membership dues, program sponsorships, and more.",
    icon: ClipboardListIcon,
  },

  {
    id: 103,
    name: 'Islamic Holiday Campaigns',
    description:
      "Whether it's Ramadan, Eid-Al-Fitr, Laylat Al-Qadr, or the first 10 Days of Dhul Hijjah — donors are encouraged to especially give during the most blessed times of the year. GiveMasjid provides donors with an opportunity to schedule their donations to coincide with these periods",
    icon: MoonIcon,
  },

  {
    id: 104,
    name: 'Real-Time Goal Tracking',
    description:
      "Your masjid can set and communicate its weekly, monthly, or annual fundraising goals to donors. Active campaigns encourage more donations week over week and month over month.",
    icon: TrendingUpIcon,
  },
  {
    id: 105,
    name: 'Donor Matching Prompts',
    description:
      "As your masjid receives donations, donors will see anonymized prompts showcasing your masjid's largest donations. Subtle nudges encourage donors to offer higher sums than they might otherwise.",
    icon: UsersIcon,
  },
  {
    id: 106,
    name: 'Simple School Payment System',
    mobilename: "School Payment Collection",
    description:
      "Streamline your masjid's Sunday School or Islamic Program enrollments. Manage one-time payments or monthly payment plans effortlessly. Parents can preschedule their payments and your masjid receives these funds without reminders.",
    icon: AcademicCapIcon,
  },
]

export default function Platform2() {

  const [isSlideshow1Visible, setIsSlideshow1Visible] = useState(false);
  const [isSlideshow2Visible, setIsSlideshow2Visible] = useState(false);
  const [hasSlideshow1Looped, setHasSlideshow1Looped] = useState(false);
  const [hasSlideshow2Looped, setHasSlideshow2Looped] = useState(false);
  const slideshow1Ref = useRef(null);
  const slideshow2Ref = useRef(null);

  const [expandedFeature, setExpandedFeature] = useState(null);
  const [expandedFeatures, setExpandedFeatures] = useState({});

  const toggleFeature = (id) => {
    setExpandedFeature(expandedFeature === id ? null : id);
  };

  const renderFeature = (item) => {
    const isExpanded = expandedFeature === item.id;
  
    return (
      <div key={item.id} className="relative mb-4 sm:mb-8">
        <div className="border border-gray-200 rounded-lg sm:border-none sm:rounded-none overflow-hidden shadow-md sm:shadow-none">
          <div 
            className="flex items-center justify-between cursor-pointer sm:cursor-default px-4 py-3 sm:p-0"
            onClick={() => toggleFeature(item.id)}
          >
            <div className="flex items-center flex-grow">
              <div className="flex-shrink-0 mr-3 sm:mr-4">
                <div className="flex items-center justify-center h-9 w-9 sm:h-10 sm:w-10 rounded-md bg-indigo-100 text-indigo-600">
                  <item.icon className="h-5 w-5" aria-hidden="true" />
                </div>
              </div>
              <h4 className="sm:text-xl font-semibold sm:font-medium text-gray-900">
                <span className="sm:hidden">{item.mobilename || item.name}</span>
                <span className="hidden sm:inline">{item.name}</span>
              </h4>
            </div>
            <div className="sm:hidden">
              {isExpanded ? (
                <ChevronUpIcon className="h-5 w-5 text-gray-500 transition-transform duration-300 ease-in-out" />
              ) : (
                <ChevronDownIcon className="h-5 w-5 text-gray-500 transition-transform duration-300 ease-in-out" />
              )}
            </div>
          </div>
          <div 
            className={`overflow-hidden transition-all duration-300 ease-in-out sm:transition-none ${
              isExpanded ? 'max-h-96' : 'max-h-0'
            } sm:max-h-none`}
          >
            <div className="px-4 pb-4 sm:p-0">
              <p className="mt-1 sm:mt-2 text-base text-gray-500">{item.description}</p>
            </div>
          </div>
        </div>
      </div>
    );
  };


  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.25
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.target === slideshow1Ref.current) {
          setIsSlideshow1Visible(entry.isIntersecting);
        } else if (entry.target === slideshow2Ref.current) {
          setIsSlideshow2Visible(entry.isIntersecting);
        }
      });
    }, options);

    if (slideshow1Ref.current) {
      observer.observe(slideshow1Ref.current);
    }
    if (slideshow2Ref.current) {
      observer.observe(slideshow2Ref.current);
    }

    return () => {
      if (slideshow1Ref.current) {
        observer.unobserve(slideshow1Ref.current);
      }
      if (slideshow2Ref.current) {
        observer.unobserve(slideshow2Ref.current);
      }
    };
  }, []);

  const handleSlideshow1End = () => {
    if (!hasSlideshow1Looped) {
      setHasSlideshow1Looped(true);
    }
  };

  const handleSlideshow2End = () => {
    if (!hasSlideshow2Looped) {
      setHasSlideshow2Looped(true);
    }
  };

  return (
    <div className="bg-white overflow-hidden pt-5 sm:py-24 md:py-6" id="features">

        <div className="relative max-w-xl mx-auto px-6 sm:px-6 lg:px-8 lg:max-w-7xl" >

          {/* Added centered headline */}
          <div className="sm:text-center mb-5 mt-10 sm:mt-1 md:mt-10">
          <h2 className="text-3xl sm:text-5xl font-extrabold text-gray-900 inline-block pb-4">
            <span className="sm:hidden">Built Just for <span className="text-indigo-600">Masjids</span></span>
            <span className="hidden sm:inline">A Solution Built Just for <span className="text-indigo-600">Masjids</span></span>
          </h2>
            <div className="relative inline-block max-w-5xl mx-auto pt-4">
              <div className="absolute top-0 left-0 w-full h-1 bg-indigo-500"></div>
              <p className="text-lg md:text-xl  text-gray-800 font-light">
                Why use a generic payment solution, when you can work with a team invested in your masjid's success?
              </p>
            </div>
          </div>

          {/* Feature 2 */}
          <div className="relative mt-8 lg:mt-10 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center pt-6">
            <div className="relative">
              <h3 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
                <span className="sm:hidden">Features to Inspire <span className="text-indigo-600">Giving</span></span>
                <span className="hidden sm:inline">Donor Features to <span className="text-indigo-600">Inspire Giving</span></span>
              </h3>
              
              <p className="mt-3 sm:text-lg text-gray-500">
                We're focused exclusively on the Muslim community. As such, we take input from our masjid network to create features specifically designed around their needs.
              </p>

              <dl className="mt-5 sm:mt-10 sm:space-y-10">
                {features4.map(renderFeature)}
              </dl>
                {/* <div className="text-center mt-10">
                  <div className="mt-3 sm:mt-0">
                      <a
                        href="/request-trial"
                        className="flex items-center justify-center px-8 py-3 border shadow-md text-sm font-medium rounded-md text-indigo-1000 bg-indigo-100 border-slate-300 border-3 hover:bg-indigo-600 hover:text-white hover:font-bold"
                      >
                        > Request a Trial
                      </a>
                  </div>
                </div> */}
            </div>


            <div className="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
              <svg
                className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
                width={784}
                height={404}
                fill="none"
                viewBox="0 0 784 404"
              >
                <defs>
                  <pattern
                    id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width={784} height={404} fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)" />
              </svg>
              <div ref={slideshow1Ref} className="slide-container mx-auto md:mt-12">
              <Slide
                  autoplaypauseOnHover={true}
                  autoplay={isSlideshow1Visible && !hasSlideshow1Looped}
                  pauseOnHover={true}
                  canSwipe={true}
                  indicators={true} 
                  arrows={true} 
                  duration={4500}
                  infinite={false}
                  transitionDuration={800}
                  easing={"ease"}
                  onEnded={handleSlideshow1End}

                >
                  {images1.map((image, index) => (
                    <div className="each-slide flex justify-center" key={index}>
                      <div
                        className="slide-container"
                        style={{
                          width: '75%', // Adjust the width as needed
                          height: 'auto', // The height will be automatically adjusted
                        }}
                      >
                        <img className="sm:rounded-xl mx-auto lg:mt-5" src={image} alt={`Slide ${index}`} />
                      </div>
                    </div>
                  ))}
                </Slide>
              </div>
            </div>
          </div>

          {/* Graphic */}
          <svg
            className="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12"
            width={404}
            height={784}
            fill="none"
            viewBox="0 0 404 784"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width={404} height={784} fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" />
          </svg>

          {/* Feature 3 */}
          <div className="relative mt-12 sm:mt-16 lg:mt-24" id="platform">
            <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
              <div className="lg:col-start-2">
              <h3 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
                  <span className="sm:hidden"><span className="text-indigo-600">Easy</span> Masjid Management</span>
                  <span className="hidden sm:inline">Masjid Management Made <span className="text-indigo-600">Easy.</span></span>
                </h3>
                <p className="mt-3 text-base sm:text-lg text-gray-500">
                  We've built GiveMasjid from the ground-up with masjids in mind. Not only will your donors enjoy the donation experience, but so too will your masjid administrators.
                </p>

                <dl className="mt-5 sm:mt-10 sm:space-y-10">
                  {features3.map(renderFeature)}
                </dl>
                <div className="text-center mt-10">
                  {/* <div className="mt-3 sm:mt-0">
                      <HashLink
                        smooth to="/#reach-us"
                        className="flex items-center justify-center px-8 py-3 border shadow-md text-sm font-medium rounded-md text-indigo-1000 bg-indigo-100 border-slate-300 border-3  hover:bg-indigo-600 hover:text-white hover:font-bold"
                      >
                        > Get In Touch
                      </HashLink>
                  </div> */}

                </div>
              </div>

              <div className="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
                <svg
                  className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
                  width={784}
                  height={404}
                  fill="none"
                  viewBox="0 0 784 404"
                  aria-hidden="true"
                >
                  <defs>
                    <pattern
                      id="e80155a9-dfde-425a-b5ea-1f6fadd20131"
                      x={0}
                      y={0}
                      width={20}
                      height={20}
                      patternUnits="userSpaceOnUse"
                    >
                      <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                    </pattern>
                  </defs>
                  <rect width={784} height={404} fill="url(#e80155a9-dfde-425a-b5ea-1f6fadd20131)" />
                </svg>
                <div ref={slideshow2Ref} className="slide-container mx-auto mb-16">
                <Slide
                  autoplaypauseOnHover={true}
                  autoplay={isSlideshow2Visible && !hasSlideshow2Looped}
                  canSwipe={true}
                  indicators={true} 
                  arrows={true} 
                  duration={4500}
                  infinite={false}
                  transitionDuration={800}
                  easing={"ease"}
                  onEnded={handleSlideshow2End}

                >
                  {images2.map((image, index) => (
                    <div className="each-slide flex justify-center" key={index}>
                      <div
                        className="slide-container"
                        style={{
                          width: '75%', // Adjust the width as needed
                          height: 'auto', // The height will be automatically adjusted
                        }}
                      >
                        <img className="sm:rounded-xl mx-auto lg:mt-5" src={image} alt={`Slide ${index}`} />
                      </div>
                    </div>
                  ))}
                </Slide>
              </div>
              </div>
            </div>
          </div>

        </div>
    </div>
  )
}
