/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState, useEffect } from 'react'
import {useParams, useSearchParams, useLocation, useNavigate, Navigate} from "react-router-dom";
import confetti from "canvas-confetti";
import CelebrationModal from "../components/CelebrationModal.jsx"


// Components
import Banner from "../components/banner.jsx"
import Nav from "../components/Nav.jsx"
import Hero from "../components/Hero.jsx"
import Headline from "../components/Headline.jsx"
import Platform from "../components/Platform.jsx"
import Platform2 from "../components/Platform2.jsx"

import Stats from "../components/Stats.jsx"
import Mission from "../components/Mission.jsx"
import Testimonials from "../components/Testimonials.jsx"
import Video from "../components/Video.jsx"
import TestimonialHighlight from "../components/TestimonialHighlight.jsx"
import MasjidTestimonials from "../components/MasjidTestimonials.jsx"
import CTA from "../components/CTA.jsx"
import Footer from "../components/Footer.jsx"

import FomoNotification from "../components/FomoNotification.jsx"
import TrialButton from "../components/TrialButton.jsx"

const count = 100;
const defaults = {
  origin: { y: 0.7 },
  zIndex: 1500,
  // colors: ["#768C7F", "#FFFFFF"]
};

function fire(particleRatio, opts) {
  confetti({
    ...defaults,
    ...opts,
    particleCount: Math.floor(count * particleRatio)
  });
}

function randomInRange(min, max) {
  return Math.random() * (max - min) + min;
}

const fireConfetti = () => {
  const duration = 3 * 1000;
  const animationEnd = Date.now() + duration;
  const interval = setInterval(function() {
    const timeLeft = animationEnd - Date.now();

    if (timeLeft <= 0) {
      return clearInterval(interval);
    }

    const particleCount = 50 * (timeLeft / duration);
    
    confetti({
      ...defaults,
      particleCount,
      origin: { x: randomInRange(0.1, 0.9), y: Math.random() - 0.2 }
    });
    confetti({
      ...defaults,
      particleCount,
      origin: { x: randomInRange(0.1, 0.9), y: Math.random() - 0.2 }
    });
  }, 250);
};

function Home() {

 // Capture search parameter for the purpose of 
  let location = useLocation();

// Celebration Modal 
const [isCelebrationModalOpen, setIsCelebrationModalOpen] = useState(false);
const [isCelebrationModalVisible, setIsCelebrationModalVisible] = useState(false);


  // Capture the scroll location
  const [showTrialButton, setShowTrialButton] = useState(false);
  const [isTrialButtonVisible, setIsTrialButtonVisible] = useState(false);
  const [showFomoNotification, setShowFomoNotification] = useState(true);

  const scrollThresholdTop = 500; // Adjust this value to your desired scroll position
  const scrollThresholdBottom = 500; // Adjust this value to your desired scroll position



  // Modal Status
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  useEffect(() => {
    const endDate = new Date('2024-12-01'); // Set through December 1st
    const today = new Date();

    if (today <= endDate) {

      // Delay the modal appearance by 2 seconds
      setTimeout(() => {
        fireConfetti();
        setIsCelebrationModalOpen(true);
        // Trigger fade-in effect after a short delay
        setTimeout(() => {
          setIsCelebrationModalVisible(true);
        }, 50);
      }, 1000);
    }
  }, []);

  useEffect(() => {

    var hash = location.hash.substring(1); 
    console.log(hash);

    if(hash== ""){
      window.scrollTo(0, 0);
    }

    if(document.getElementById(hash) != null) {
      setTimeout(() => {
        document.getElementById(hash).scrollIntoView();
      }, 250)      
    }

  }, [location]);

  useEffect(() => {
    // Timer to hide FomoNotification after 45 seconds
    const fomoTimer = setTimeout(() => {
      setShowFomoNotification(false);
    }, 45000); // 45 seconds

    // Cleanup function to clear the timer if the component unmounts
    return () => clearTimeout(fomoTimer);
  }, []); // Empty dependency array means this effect runs once on mount

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.pageYOffset;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const scrollThresholdTop = 500; // Adjust this value to your desired scroll position from the top
      const scrollThresholdFromBottom = 200; // Adjust this value to your desired threshold from the bottom
  
      // Calculate the scroll position relative to the bottom of the page
      const distanceFromBottom = documentHeight - scrollPosition - windowHeight;
  
      const shouldShowButton =
        scrollPosition > scrollThresholdTop &&
        distanceFromBottom > scrollThresholdFromBottom;
  
      setShowTrialButton(shouldShowButton);
  
      if (shouldShowButton && !isTrialButtonVisible) {
        setIsTrialButtonVisible(true);
      } else if (!shouldShowButton && isTrialButtonVisible) {
        setIsTrialButtonVisible(false);
      }
    };
  
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isTrialButtonVisible]);
  

  return (
    <div>
      <CelebrationModal 
        isOpen={isCelebrationModalOpen} 
        onClose={() => {
          setIsCelebrationModalVisible(false);
          setTimeout(() => setIsCelebrationModalOpen(false), 300); // Delay closing to allow fade-out
        }}
        isVisible={isCelebrationModalVisible}
      />
      <Banner />
      {!isModalOpen && <Nav isModalOpen={isModalOpen} />}
      <Hero isModalOpen={isModalOpen} openModal={openModal} closeModal={closeModal} /> 
      <Video />

      {/* <Headline /> */}
      <Platform />
      <Stats />
      {/* <TestimonialHighlight /> */}
      <Platform2 />
      <MasjidTestimonials />
      {/* <Testimonials /> */}
      {/* <MasjidTestimonials /> */}
      <Mission />
      <CTA />
      <Footer />
      {showTrialButton && (
        <>
          <TrialButton isVisible={isTrialButtonVisible} />
          {showTrialButton && showFomoNotification && <FomoNotification />}
        </>
      )}
    </div>
  );
}

export default Home;
